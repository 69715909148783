import React from 'react'

import CoreRenderFunction from "DisplayCore"
import { handleNestedObjectKey } from 'utils'

const CustomList = props => {
  let {
    dataKey,
    children
  } = props.item
  return handleNestedObjectKey( props, dataKey )?.map( (val, index) => {
    return(
      <CoreRenderFunction { ...props } item={ children } listItem={ val } listIndex={index+1}/>
    )
  })
}

export default CustomList